<template>
  <fw-layout>
    <template #main-sidebar>
      <SidebarManage />
    </template>
    <template #main-content>
      <PanelNotifications />
    </template>
  </fw-layout>
</template>

<script>
import SidebarManage from '@/components/sidebars/SidebarManage'
import PanelNotifications from '@/fw-modules/fw-core-vue/ui/components/panels/PanelNotifications'

export default {
  name: 'ManageNotifications',
  components: {
    SidebarManage,
    PanelNotifications,
  },
}
</script>
